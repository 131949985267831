import Boom from 'boom';
import extend from 'lodash/extend';
import { parseUrl } from 'query-string';
import Axios, { AxiosRequestConfig, AxiosResponse, Method, AxiosError } from 'axios';
import { ensureProtocol, queryObjToString } from '../utils/uri';

export interface ApiCallConfig extends AxiosRequestConfig {
  method: Method;
  url: string;
  query?: Record<string, string | undefined>;
}

function handleAxiosErr(err: AxiosError<Boom>) {
  const statusCode = err.response?.status;
  if (statusCode && statusCode.toString().startsWith('4')) {
    const errorMessage = err.response?.data?.message;
    if (errorMessage) {
      const clientError = new Error(errorMessage);
      clientError.name = 'ClientError';
      return clientError;
    }
  }
  return err;
}

async function ApiCall(cfg: ApiCallConfig): Promise<AxiosResponse> {
  // set some defaults, withCredentials can be forced to false from above
  if (cfg.withCredentials == null) {
    cfg.withCredentials = true;
  }

  // ensure https
  cfg.url = ensureProtocol(cfg.url, 'https:');

  // process query object (not supported by axios itself)
  if (cfg.query) {
    const parsed = parseUrl(cfg.url);
    const { url } = parsed;
    const query = parsed.query as Record<string, string | undefined>;
    extend(query, cfg.query);
    cfg.url = `${url}?${queryObjToString(query)}`;
  }

  let result;
  try {
    result = await Axios(cfg);
  } catch (err: any) {
    throw handleAxiosErr(err);
  }
  return result;
}

export default ApiCall;
