/* eslint-disable no-console */

import { appEnv } from '../app-config';

export const log = {
  info: (msg: string) => console.log(msg),
  warn: (msg: string) => console.warn(msg),
  error: (msg: string) => console.error(msg),
  debug: (...args: any[]) => {
    if (appEnv === 'development') {
      console.debug(...args);
    }
  },
};
export const logInfo = log.info;
export const logWarn = log.warn;
export const logError = log.error;
