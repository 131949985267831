/* eslint-disable no-param-reassign */

import i18n from 'i18next';
import { initReactI18next, useTranslation, UseTranslationOptions } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { log } from './utils/log';
import { appEnv, authApiHost } from './app-config';

const appName = 'campaign-widget-app' as const;
export type Namespace = typeof appName | 'survey-tool' | 'languages';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: appName,
    lowerCaseLng: true, // we store them like this in the db
    fallbackLng: (locale: string) => {
      const lang = locale.split(/[^a-zA-Z0-9]/)[0];
      log.debug(`fallbackLng ${locale} -> ${lang}`);
      return lang;
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    debug: appEnv === 'development',
    saveMissing: true,
    saveMissingTo: 'fallback',
    backend: {
      loadPath: `${authApiHost}/api/v1/translations/load/{{ns}}/{{lng}}`,
      addPath: `${authApiHost}/api/v1/translations/add/{{ns}}/{{lng}}`,
      crossDomain: true,
      withCredentials: true,
      customHeaders: {
        'X-App-Name': appName,
      },
      // parse: (data) => ({}), // disable fetching translations from backend
    },
  });

export default i18n;

// const defaultMap: Record<string, string> = {};

export function useAppTranslation(ns?: Namespace, options?: UseTranslationOptions<any>) {
  return useTranslation(ns, options);
  // const res = useTranslation(ns, options);
  // const rv = useMemo(() => {
  //   const _t = (
  //     key: string | string[],
  //     defaultValue: string,
  //     opts?: TOptionsBase,
  //   ) => {
  //     const k = key.toString();
  //     if (!defaultValue) {
  //       return `~err~no default value for key ${k}~`;
  //     }
  //     if (!defaultMap[k]) {
  //       defaultMap[k] = defaultValue;
  //     } else if (defaultMap[k] !== defaultValue) {
  //       return `~err~different default value for same key ${k}~`
  //     }
  //     return res.t(key, defaultValue, opts)
  //   }
  //   return { i18n: res.i18n, t: _t };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [res.i18n, res.t]);
  // return rv;
}
