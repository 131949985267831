import { authApiHost } from '../app-config';
import ApiCall from './api-call';

export type WebLogMessage = WebLogMessageLogger | WebLogMessageConstructor;

export type WebLogMessageLoggerLevel = 'info' | 'warn' | 'error';

interface WebLogMessageLogger {
  type: 'LOGGER';
  level: WebLogMessageLoggerLevel;
  message: string;
}

interface WebLogMessageConstructor {
  type: 'CONSTRUCTOR';
  options: any;
}

export function webLogPost(json: WebLogMessage): void {
  ApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/public/campaign-widget/log`,
    data: {
      url: window.location.href,
      ...json,
    },
  }).catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });
}
