import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import TinyEmitter from 'tiny-emitter';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import type SurveyContainerWrapperType from './survey-container-wrapper';
import { PartialSurveyToolStyleObject, SurveyToolStyleObject } from './survey-tool/default-styles/style-declaration';
import { GenericLoaderNoI18N } from './survey-tool/components/generic-loader';
import { appConfig } from './app-config';
import { webLogPost } from './lib/web-log';

const SurveyContainerWrapperLazyImport = lazy(() => import(/* webpackChunkName: "scw" */ './survey-container-wrapper'));

export type T_DISPLAY_MODE = 'full-screen' | 'widget';

export type T_DEVICE_MODE = 'widget' | 'auto';

export type T_AUTH_FETCH_PROFILE = 'on-mount' | 'on-mount-async' | 'before-answer-submit';

export interface IPPCampaignWidgetOptions {
  // html element to render into
  target: HTMLElement;
  // basic platform settings that can be set, defaults to Pureprofile
  platformUuid?: string;
  platformName?: string;
  // authFetchProfile:
  //
  // 1) 'on-mount' (DEFAULT) profile downloads when widget is rendered into DOM
  // 2) 'on-mount-async' profile downloads when widget is rendered into DOM, but doesn't block campaign fetching
  //    in this case campaign is fetched anonymously without profile
  // 3) 'before-answer-submit' profile downloads when user clicks the submit button of the widget
  //
  // !!! defaultAuthApiUrl & defaultInstanceCode options have to be set for NON-DEFAULT options to work
  // !!! you can also use external method
  // .ensureUserProfile(): Promise<UserProfile> in combination with 'before-answer-submit'
  authFetchProfile?: T_AUTH_FETCH_PROFILE;
  // default auth-api server to communicate with
  authApiUrl: string;
  // ah-api server to communicate with, this can be changed by user's profile
  defaultAhApiUrl?: string;
  defaultInstanceCode?: string;
  // DEPRECATED: use defaultPanelUuid instead of defaultInstanceUuid
  defaultInstanceUuid?: string;
  defaultPanelUuid?: string;
  // a) valid authToken to communicate with authApi
  useAuthToken?: string;
  // b) mode only for users who are logged in to authApiUrl through another means
  // for example my.pureprofile.com site (this mode relies on cookies)
  useCookieAuth?: boolean;
  // c) anonymous instance uuid to register with
  useAnonymousRegistration?: boolean;
  doNotSendCookiesWhenRegistering?: boolean;
  // trackingId and trackingPartnerId should be submitted with c)
  trackingId?: string;
  trackingPartnerId?: number;
  // if you don't want to get warning messages about trackingId missing, set this to true
  ignoreMissingTrackingData?: boolean;
  // businessUuid specifies a business to filter when fetching campaign list
  businessUuid?: string;
  // categoryUuid specified a category to filter when fetching campaign list
  categoryUuid?: string;
  // campaignUuid specifies a campaign to be opened by the widget
  campaignUuid?: string;
  // optional styling objects, both can be single object or an array
  // last value in the array overwrites values before it
  preStyleObj?: PartialSurveyToolStyleObject | PartialSurveyToolStyleObject[];
  postStyleObj?: PartialSurveyToolStyleObject | PartialSurveyToolStyleObject[];
  processStyleObjFn?: (styleObj: SurveyToolStyleObject) => SurveyToolStyleObject;
  // by default the widget is display in a widget mode,
  // which is also used in app feeds when displaying polls
  displayMode?: T_DISPLAY_MODE;
  // by default the widget present itself as device=widget to the api's
  // it can be overridden to allow auto-detection on ah-api's side
  deviceMode?: T_DEVICE_MODE;
  // force load campaign data when loading thank you page in order to prevent showing the next button
  hideNextButton?: boolean;
  // force skipping of the invitation page
  skipInvitation?: boolean;
  // force skipping of the thank you page
  skipThankYou?: boolean;
  height250?: boolean;
  closeButtonDef?: {
    onClick: () => void;
    label: string;
  };
  // url of the logo to show as a loading animation, defaults to Pureprofile's logo
  loadingLogoUrl?: string;
}

class PPCampaignWidget extends (TinyEmitter as any) {
  private options: IPPCampaignWidgetOptions;
  private surveyContainerWrapperRef: React.RefObject<SurveyContainerWrapperType>;

  constructor(options: IPPCampaignWidgetOptions) {
    super();
    this.validateOptions(options);
    this.options = options;
    this.surveyContainerWrapperRef = React.createRef();
    webLogPost({
      type: 'CONSTRUCTOR',
      options: pickBy(options, (val) => typeof val !== 'object' || isPlainObject(val) || isArray(val)),
    });
    window.React ??= React;
    render(
      <Suspense fallback={<GenericLoaderNoI18N />}>
        <SurveyContainerWrapperLazyImport
          {...this.options}
          emitter={this as any}
          ref={this.surveyContainerWrapperRef}
        />
      </Suspense>,
      this.options.target
    );
  }

  validateOptions(options: IPPCampaignWidgetOptions) {
    const required: Array<keyof IPPCampaignWidgetOptions> = ['authApiUrl'];
    required.forEach((prop) => {
      if (!options[prop]) {
        throw new Error(`${prop} must be defined in widget options!`);
      }
    });
  }

  async ensureUserProfile() {
    const surveyContainerWrapper = this.surveyContainerWrapperRef.current;
    if (!surveyContainerWrapper) {
      throw Error(`Unexpeced call, SurveyContainerWrapper has not been rendered yet!`);
    }
    return surveyContainerWrapper.ensureUserProfile();
  }
}

function loadPureprofileFonts() {
  // Load pureprofile fonts
  const { cdn } = appConfig();
  const css = `
    @font-face {
      font-family: 'Pureprofile';
      src:
        url('${cdn}/fonts/pureprofile.ttf') format('truetype'),
        url('${cdn}/fonts/pureprofile.woff') format('woff'),
        url('${cdn}/fonts/pureprofile.svg') format('svg');
    }
  `;
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);
}

loadPureprofileFonts();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).PPCampaignWidget = PPCampaignWidget;
