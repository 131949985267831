/* eslint-disable no-restricted-globals */

import extend from 'lodash/extend';
import omitBy from 'lodash/omitBy';
import { stringify as qsStringify } from 'query-string';

export function getCurrentLocationWithoutSearch() {
  return [location.protocol, '//', location.host, location.pathname].join('');
}

export function ensureProtocol(url: string, protocol?: string): string {
  const p = protocol || location.protocol;
  const hasProtocol = /^https?:/.test(url);
  if (hasProtocol) {
    return url;
  }
  const hasTwoSlashes = url.startsWith('//');
  if (hasTwoSlashes) {
    return p + url;
  }
  const hasOneSlash = url.startsWith('/');
  if (hasOneSlash) {
    return `${p}//${location.host}${url}`;
  }
  // hasNoSlashes
  return `${p}//${url}`;
}

export function queryToObj(query: string = location.search): Record<string, string | undefined> {
  const str = query.startsWith('?') ? query.slice(1) : query;
  return str.split('&').reduce((obj: Record<string, string | undefined>, pair) => {
    const split = pair.split('=');
    const key = split[0] ? decodeURIComponent(split[0]) : null;
    const value = split[1] ? decodeURIComponent(split[1]) : null;
    if (key && value != null) {
      obj[key] = value;
    }
    return obj;
  }, {});
}

// NOTE: this doesn't return query mark (?)
export function queryObjToString(obj: Record<string, string | undefined>): string {
  return qsStringify(omitBy(obj, (val) => val == null || val === ''));
}

export function addToQueryString(url: string, obj: Record<string, string | undefined>) {
  const parts = url.split('?');
  const queryObj = queryToObj(parts[1] || '');
  extend(queryObj, obj);
  return `${parts[0]}?${queryObjToString(queryObj)}`;
}
