/* eslint-disable no-process-env */

export interface AppConfig {
  authApiHost: string;
  cdn: string;
}

export const nodeEnv = process.env.NODE_ENV;

export type APP_ENV = 'production' | 'staging' | 'development' | 'test';

export const appEnv: APP_ENV = (process.env.REACT_APP_ENV as APP_ENV) || 'development';

export function appConfig(): AppConfig {
  if (appEnv === 'production') {
    return {
      authApiHost: 'https://pp-auth-api.pureprofile.com',
      cdn: 'https://widgets.pureprofile.net/campaign-widget',
    };
  }
  if (appEnv === 'staging') {
    return {
      authApiHost: 'https://staging-auth-api.pureprofile.com',
      cdn: 'https://widgets-staging.pureprofile.net/campaign-widget',
    };
  }
  if (appEnv === 'development') {
    return {
      authApiHost: 'https://dev-auth-api.pureprofile.com',
      cdn: 'https://widgets-dev.pureprofile.net/campaign-widget',
    };
  }
  throw new Error(`Unexpected appEnv=${appEnv}`);
}

export const { authApiHost } = appConfig();
